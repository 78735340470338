@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?hyd4f2");
  src: url("fonts/icomoon.eot?hyd4f2#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?hyd4f2") format("truetype"),
    url("fonts/icomoon.woff?hyd4f2") format("woff"),
    url("fonts/icomoon.svg?hyd4f2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="tod-"],
[class*=" tod-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tod-chat-icon:before {
  content: "\e900";
  color: #9a9a9a;
}
.tod-chat-icon-listing:before {
  content: "\e901";
  color: #fff;
}
.tod-check-circle:before {
  content: "\e902";
  color: #fff;
}
.tod-courses:before {
  content: "\e903";
}
.tod-dashboard:before {
  content: "\e904";
}
.tod-dashboard-assignment:before {
  content: "\e905";
  color: #443cc5;
}
.tod-dashboard-courses:before {
  content: "\e906";
  color: #0092e6;
}
.tod-dashboard-parent:before {
  content: "\e907";
  color: #ff3a29;
}
.tod-dashboard-quizzes:before {
  content: "\e908";
  color: #ffb200;
}
.tod-notification-bell:before {
  content: "\e909";
  /* color: #9a9a9a; */
}
.tod-parents:before {
  content: "\e90a";
}
.tod-payment:before {
  content: "\e90b";
}
.tod-refund:before {
  content: "\e90c";
  color: #fff;
}
.tod-students:before {
  content: "\e90d";
}
.tod-trash:before {
  content: "\e90e";
  color: #fff;
}
.tod-upload:before {
  content: "\e90f";
  color: #43425d;
}
